<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        试卷管理
      </template>
      <template #input>
        <a-input placeholder="请输入关键字" v-model="title"/>
        <a-button type="primary" class="btn" :loading="loading"  @click.native="search">搜索</a-button>
      </template>
    </HeaderBox>
    <div class="function">
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" tab="考试"></a-tab-pane>
        <a-tab-pane key="2" tab="模拟考试"></a-tab-pane>
      </a-tabs>
      <div>
        <a-button type="primary" class="btn" @click="onmanualGear()">手动组卷</a-button>
        <a-button type="primary" class="btn" @click="onAutomaticPage">自动组卷</a-button>
      </div>
    </div>
    <!-- 表格列表 -->
    <div class="table-box">
      <div class="table-left">
        <div class="item" v-for="(item,index) in courseList" :key="index">
          <p class="item-name" :class="{'item-name-active':item.isOpen}" :title="item.courseName" @click="changeCourse(item,index)">{{item.courseName}}</p>
        </div>
      </div>
      <a-table
        class="table-right"
        :rowKey="item=>item.paperId"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        :locale="{emptyText: '暂无数据'}" 
        @change="onPage"
        :pagination="{
          total:total,
          current:pageNumber,
          defaultPageSize:pageSize,
          showSizeChanger: true,
          showTotal: function(total, range){
            return `共${total}条`
          }
        }">
          <template slot="index" slot-scope="item, row, i">
            <div style="text-align: center;">
              {{ (pageNumber - 1) * pageSize + i + 1 }}
            </div>
          </template>
          <template slot="operation" slot-scope="item, row">
            <div class="btn_router_link">
              <a @click="editPaper(row.paperId,row.modeName)">编辑</a>
              <span>|</span>
              <a @click="manageEnAble(row.paperId,row.statusText == '启用'?0:1)">{{row.statusText == '启用'?'停用':'启用'}}</a>
              <span v-show="row.statusText !== '启用'">|</span>
              <a v-show="row.statusText !== '启用'" @click="delPaperItem(row.paperId,row.statusText == '启用'?1:0)">删除</a>
            </div>
          </template>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }
  },
  {
    title: "试卷名称",
    align:'center',
    dataIndex: "paperName"
  },
  {
    title: "试卷类型",
    align:'center',
    dataIndex: "modeName"
  },
  {
    title: "题数",
    align:'center',
    dataIndex: "quesNum"
  },
  {
    title: "总分",
    align:'center',
    dataIndex: "totalScore"
  },
  {
    title: "状态",
    align:'center',
    dataIndex: "statusText"
  },
  {
    title: "操作",
    align:'center',
    width: "200px",
    scopedSlots: { customRender: "operation" }
  }
];

import HeaderBox from '@/components/HeaderBox.vue'

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      category: 1, // tab
      title:'', // 组卷名称
      state: 1, // 1新建，2编辑
      visible: false,
      columns, // 表头
      selectedKeys: [],
      tableData:[], // 列表数据
      loading:false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      isChecked: false, 
      selectedRowKeys:[],
      tableselectedKeys:[],
      courseId:'', // 课程id
      sectionId:'', // 章节id
      courseList:[], // 课程列表
      menuList:[
        {
          key:'1',
          isOpen:false,
          name:'孤独症课程',
          childList:[
            { title:'第一张',key:'1-1' },
            { title:'第二章',key:'1-2' },
            { title:'第二章',key:'1-3' },
          ]
        },
        {
          key:'2',
          isOpen:false,
          name:'孤独症课程',
          childList:[
            { title:'第一张',key:'2-1' },
            { title:'第二章',key:'2-2' },
            { title:'第二章',key:'2-3' },
          ]
        },
        {
          key:'3',
          isOpen:false,
          name:'孤独症课程',
          childList:[
            { title:'第一张',key:'3-1' },
            { title:'第二章',key:'3-2' },
            { title:'第二章',key:'3-3' },
          ]
        },
        {
          key:'4',
          isOpen:false,
          name:'孤独症课程',
          childList:[
            { title:'第一张',key:'4-1' },
            { title:'第二章',key:'4-2' },
            { title:'第二章',key:'4-3' },
          ]
        },
        {
          key:'5',
          isOpen:false,
          name:'孤独症课程',
          childList:[
            { title:'第一张',key:'1' },
            { title:'第二章',key:'2' },
            { title:'第二章',key:'3' },
          ]
        }
      ]
    }
  },
  // 事件处理器
  methods: {
    // 切换课程
    changeCourse(item,index){
      this.courseList.map(ite=>{
        if(ite.courseId == this.courseId){
          ite.isOpen = false;
        }
      })
      this.courseId = item.courseId;
      this.sectionId = '';
      this.tableselectedKeys = [];
      item.isOpen = true;
      this.getManageList();
    },
    // 编辑
    editPaper(e,type){
      if(type == '自动组卷'){
        this.$router.push('/admin/Examination/automaticPaper?paperId=' + e + "&category=" + this.category + '&courseId=' + this.courseId);
      }else{
        this.$router.push('/admin/Examination/preview?paperId=' + e + "&category=" + this.category + '&courseId=' + this.courseId);
      }
    },
    // 分页
    onPage(e){
      this.pageNumber = e.current
      this.pageSize = e.pageSize
    },
    // 自动组卷
    onAutomaticPage(){
      this.$router.push({ path: "/admin/Examination/automaticPaper?category=" + this.category + '&courseId=' + this.courseId })
    },
    // 手动组卷
    onmanualGear(){
      this.$router.push({ path: "/admin/Examination/manualGear?category=" + this.category + '&courseId=' + this.courseId })
    },
    
    // tab切换
    callback(e){
      this.category = e;
      this.getCourseList();
      // this.getManageList();
    },
    // 跳详情页
    goDetails(){
      this.$router.push({ path: "/admin/UserManage/userDetail", query: { id: 1 } })
    },
    // 删除
    delPaperItem(e,status){
      this.$ajax({
        url:'/hxclass-management/exam/group/del?paperId=' + e + '&status=' + status,
        method:'delete',
        // params:{
        //   paperId:e,
        //   status:status
        // }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.$message.info('删除成功');
          this.getManageList();
        }else{
          this.$message.error(res.message);
        }
      })
    },
    // 停用启用
    manageEnAble(paperId,status){
      this.$ajax({
        url:'/hxclass-management/exam/group/enable?paperId='+paperId+"&status="+status,
        method:"put"
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.$message.info(status==1?'启用成功':'停用成功');
          this.getManageList();
        }else{
          this.$message.error(res.message);
        }
      })
    },
     // 搜索
    search() {
      this.pageNumber = 1;
      // this.pageSize = 10;
      this.getManageList();
    },
    // 获取试卷列表
    getManageList(){
      this.loading = true
      this.$ajax({
        url:"/hxclass-management/exam/group/list",
        method:"get",
        params:{
          category:this.category, // 组卷大类 1.考试 2.模拟考试
          courseId:this.courseId, // 课程id
          name:this.title, // 组卷名称
          current:this.pageNumber,
          size:this.pageSize
        }
      }).then(res=>{
        this.loading = false
        if(res.code == 200 && res.success){
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      })
    },
    // 获取课程列表
    getCourseList(){
      this.$ajax({
        url:'/hxclass-management/course/exam/box',
        method:'get',
        params:{
          type: this.category,
          pageNum:1,
          pageSize:1000
        }
      }).then((res)=>{
        if(res.code == 200 && res.success){
          this.courseId = res.data.records[0].courseId;
          this.courseList = res.data.records.map((item,index)=>{
            item.isOpen = index === 0?true:false;
            item.children = [];
            return item;
          })
          this.getManageList();
        }else{
          this.$message.error(res.message);
        }
      })
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getCourseList(); // 获取课程列表
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.btn{
  margin-right: 24px;
}
.function{
  display: flex;
  justify-content: space-between;
  align-items: center;
  /deep/ .ant-tabs-bar{
    border-bottom: none;
  }
  .all_boder_btn{
    margin-right: 20px;
  }
}
.table-box{
  .table-left{
    float: left;
    width: 337px;
    margin-right: 40px;
    border: 1px solid #EEEEEE;
    .item{
      border-bottom: 1px solid #EEEEEE;
      cursor: pointer;
      .item-name{
        // width: 168px;
        font-size: 14px;
        color: #333333;
        padding-left: 20px;
        line-height: 45px;
        display: flex;
        align-items: center;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .item-name-active{
        color: #FFFFFF;
        background: #2B6ED4;
      }
    }
    .item:last-child{
      border-bottom:none
    }
    
  }
  .table-right{
    float: left;
    width: calc(100% - 377px);
  }
  /deep/ .ant-tabs-bar{
    border-bottom:none
  }
}
.all_content_box{
  .line-item{
    margin-bottom: 20px;
    display: flex;
    .all_left_name{
      width: 90px;
      text-align: right;
      margin-right: 20px;
    }
  }
  .right{
    flex: 1;
    /deep/.ant-input{
      height: 40px;
    }
  }
  textarea.ant-input{
    flex: 1;
  }
}
</style>
