var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 试卷管理 ")]},proxy:true},{key:"input",fn:function(){return [_c('a-input',{attrs:{"placeholder":"请输入关键字"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('a-button',{staticClass:"btn",attrs:{"type":"primary","loading":_vm.loading},nativeOn:{"click":function($event){return _vm.search($event)}}},[_vm._v("搜索")])]},proxy:true}])}),_c('div',{staticClass:"function"},[_c('a-tabs',{attrs:{"default-active-key":"1"},on:{"change":_vm.callback}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"考试"}}),_c('a-tab-pane',{key:"2",attrs:{"tab":"模拟考试"}})],1),_c('div',[_c('a-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.onmanualGear()}}},[_vm._v("手动组卷")]),_c('a-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":_vm.onAutomaticPage}},[_vm._v("自动组卷")])],1)],1),_c('div',{staticClass:"table-box"},[_c('div',{staticClass:"table-left"},_vm._l((_vm.courseList),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('p',{staticClass:"item-name",class:{'item-name-active':item.isOpen},attrs:{"title":item.courseName},on:{"click":function($event){return _vm.changeCourse(item,index)}}},[_vm._v(_vm._s(item.courseName))])])}),0),_c('a-table',{staticClass:"table-right",attrs:{"rowKey":function (item){ return item.paperId; },"columns":_vm.columns,"loading":_vm.loading,"data-source":_vm.tableData,"locale":{emptyText: '暂无数据'},"pagination":{
        total:_vm.total,
        current:_vm.pageNumber,
        defaultPageSize:_vm.pageSize,
        showSizeChanger: true,
        showTotal: function(total, range){
          return ("共" + total + "条")
        }
      }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s((_vm.pageNumber - 1) * _vm.pageSize + i + 1)+" ")])]}},{key:"operation",fn:function(item, row){return [_c('div',{staticClass:"btn_router_link"},[_c('a',{on:{"click":function($event){return _vm.editPaper(row.paperId,row.modeName)}}},[_vm._v("编辑")]),_c('span',[_vm._v("|")]),_c('a',{on:{"click":function($event){return _vm.manageEnAble(row.paperId,row.statusText == '启用'?0:1)}}},[_vm._v(_vm._s(row.statusText == '启用'?'停用':'启用'))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(row.statusText !== '启用'),expression:"row.statusText !== '启用'"}]},[_vm._v("|")]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(row.statusText !== '启用'),expression:"row.statusText !== '启用'"}],on:{"click":function($event){return _vm.delPaperItem(row.paperId,row.statusText == '启用'?1:0)}}},[_vm._v("删除")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }